import { Injectable } from '@angular/core';
import { User } from './user.model';
import { EJSON } from 'bson';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { AuthService } from 'mylib/services/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseApiService } from 'mylib/services/firebase/firebase.api.service';
import { AlertController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { MainService } from 'mylib-user/services/main/main.service';
import { StorageService } from 'mylib-user/services/storage/storage.service';

@Injectable({
	providedIn: 'root',
})
export class UserProviderService {
	loggedInUser: User = {} as User;
	getStartedPersonal: any;

	constructor(
		private storage: StorageService,
		private main: MainService,
		private afAuth: AngularFireAuth,
		private auth: AuthService,
		private firebaseApi: FirebaseApiService,
		private alertController: AlertController,
		private navCtrl: NavController,
		private router: Router,
		private googlePlus: GooglePlus
	) {}

	checkAuthState() {
		return new Promise(async (resolve, reject) => {
			const unsubscribe = await this.afAuth.onAuthStateChanged((user) => {
				unsubscribe();
				this.load(user?.uid).then(() => resolve(user));
			}, reject);
		});
	}

	async setLoggedInUser(user) {
		Object.assign(this.loggedInUser, user);
	}

	getFingerprint() {
		return new Promise<string>((resolve, reject) => {
			FingerprintJS.load()
				.then((fp) => fp.get())
				.then((result) => {
					const visitorId = result.visitorId;
					resolve(visitorId);
				});
		});
	}

	load(userId) {
		return new Promise(async (resolve, reject) => {
			const isLoginExpired = (user: User) => {
				console.log('User -> ', user);

				const expiredDate = user.lastSeen.seconds / 60 + 15;

				const nowDate = new Date().getTime() / 60000; // To Minutes

				console.log(
					'Now Date -> ',
					nowDate,
					'Expired Date -> ',
					expiredDate
				);

				if (nowDate >= expiredDate) {
					return true;
				} else {
					return false;
				}
			};
			try {
				console.log(5);
				if (userId) {
					const user = await this.auth.getSingleUser(userId);
					console.log('Get User -> ', user);

					if (user && user.role !== 'user')
						throw new Error('not a user');

					await this.setLoggedInUser(user);
					const loginExpired = isLoginExpired(user);
					if (loginExpired) {
						this.logout();
						return;
					}

					await this.lastSeen(new Date());
					this.main.state.userProvider = this.loggedInUser;
					await this.main.getTasks(this.loggedInUser.id);
					await this.main.getAppointments(this.loggedInUser.id);
					await this.main.getWorkRequests(this.loggedInUser.id);
					console.log('re-create state ->', this.main.state);
					this.main.listenTaskChanges(this.loggedInUser.id);
					this.main.listenConversations(this.loggedInUser.id);
					this.main.listenWorkRequestChanges(this.loggedInUser.id);
					this.main.listenAppointmentsChange(this.loggedInUser.id);
					resolve(true);
				} else {
					resolve(false);
					await this.storage.init();
				}
			} catch (e) {
				await this.storage.init();
				console.log('e-=>', e.message);
				if (e.message === 'not a user') {
					resolve('not a user');
				}
			}
		});
	}

	async incomingCallReceived(incomingCall: any) {}

	async logout(_isShortLogout?: boolean): Promise<any> {
		try {
			await this.googlePlus.disconnect();
		} catch (e) {
		} finally {
			console.log('Call Logout', this.loggedInUser);

			if (!_isShortLogout)
				await this.firebaseApi.updateOne(
					'users',
					this.loggedInUser.id,
					{
						verifiedAuth: false,
					}
				);

			this.main.initOrderSummary();
			await this.auth.logout();
			this.loggedInUser = {} as User;
			await this.storage.remove('nexUserGenLogin');
			this.unsubscribeListeners();
			//await this.navCtrl.navigateForward(`/login`);
			location.reload();
		}
	}

	unsubscribeListeners() {
		if (this.main.tasksListener) this.main.tasksListener.unsubscribe();

		if (this.main.workRequestsListener)
			this.main.workRequestsListener.unsubscribe();

		if (this.main.appointmentsListener)
			this.main.appointmentsListener.unsubscribe();

		if (this.main.convListner) this.main.convListner.unsubscribe();

		if (this.main.conversationClient) {
			this.main.conversationClient.removeAllListeners();
			this.main.conversationClient.shutdown();
		}
	}

	async lastSeen(date, userId?) {
		console.log('Last Seen');
		await this.firebaseApi.updateOne(
			'users',
			userId ? userId : this.loggedInUser.id,
			{
				lastSeen: date,
			}
		);
	}
}
