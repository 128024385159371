import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

export class AuthInfo {
    constructor(public $uid: string) { }

    isLoggedIn() {
        return !!this.$uid;
    }
}

@Injectable()
export class AuthenticationService {
    static unknownUser = new AuthInfo(null);

    public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(
        AuthenticationService.unknownUser
    );

    constructor(private fireAuth: AngularFireAuth) {
        this.fireAuth.authState.pipe(take(1)).subscribe((user) => {
            if (user) {
                this.authInfo$.next(new AuthInfo(user.uid));
            }
        });
    }

    signInWithCredential(credential) {
        return new Promise((resolved, reject) => {
            this.fireAuth.signInWithCredential(credential).then(
                (success) => {
                    resolved(success.user);
                },
                (err) => reject(err)
            );
        });
    }
    //

    createAccount(email: string, password: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const user = await this.fireAuth.createUserWithEmailAndPassword(
                    email,
                    password
                );

                resolve(user.user);
            } catch (e) {
                reject(e);
            }
        });
    }

    resetPassword(email) {
        return new Promise(async (resolve, reject) => {
            try {
                const reset = await this.fireAuth.sendPasswordResetEmail(email);
                resolve(reset);
            } catch (e) {
                reject(e);
            }
        });
    }

    public login(email: string, password: string): Promise<any> {
        return new Promise<any>((resolved, rejected) => {
            this.fireAuth
                .signInWithEmailAndPassword(email, password)
                .then((res) => {
                    if (res.user) {
                        resolved(res.user);
                    }
                })
                .catch((err) => {
                    rejected(err);
                });
        });
    }

    public logout(): Promise<void> {
        this.authInfo$.next(AuthenticationService.unknownUser);
        return this.fireAuth.signOut();
    }
}
