import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavigateAwayGuard } from '../../mylib-user/guards/navigate-away.guard';
import { AuthGuard } from '../../mylib-user/pages/auth/auth.guard';

const routes: Routes = [
	{
		path: 'start-screen',
		loadChildren: () =>
			import(
				'../../mylib-user/pages/start-screen/start-screen.module'
			).then((m) => m.StartScreenPageModule),
	},

	{
		path: 'login',
		loadChildren: () =>
			import('../../mylib-user/pages/auth/auth.module').then(
				(m) => m.AuthPageModule
			),
	},
	{
		path: 'register',
		loadChildren: () =>
			import('../../mylib-user/pages/auth/auth.module').then(
				(m) => m.AuthPageModule
			),
	},
	{
		path: 'support',
		loadChildren: () =>
			import('../../mylib-user/pages/support/support.module').then(
				(m) => m.SupportPageModule
			),
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('../../mylib-user/pages/dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
	},
	{
		path: 'invoices',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('../../mylib-user/pages/invoices/invoices.module').then(
				(m) => m.InvoicesPageModule
			),
	},
	{
		path: 'explore-pros',
		loadChildren: () =>
			import('./pages/pros/pros.module').then((m) => m.ProsPageModule),
	},

	{
		path: 'quest',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/quest/quest.module').then((m) => m.QuestPageModule),
	},
	{
		path: 'get-started',
		loadChildren: () =>
			import('./pages/quest/quest.module').then((m) => m.QuestPageModule),
	},
	{
		path: 'lead',
		loadChildren: () =>
			import('./pages/lead/lead.module').then((m) => m.LeadPageModule),
	},
	{
		path: 'onboarding',
		loadChildren: () =>
			import('../../mylib-user/pages/onboarding/onboarding.module').then(
				(m) => m.OnboardingPageModule
			),
	},
	{
		path: 'verify-auth',
		loadChildren: () =>
			import(
				'../../mylib-user/pages/verify-auth/verify-auth.module'
			).then((m) => m.VerifyAuthPageModule),
	},

	{
		path: 'verify-email/:id',
		// canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/verify-email/verify-email.module').then(
				(m) => m.VerifyEmailPageModule
			),
	},

	{
		path: 'list',
		loadChildren: () =>
			import('./pages/list/list.module').then((m) => m.ListModule),
	},

	{
		path: 'splash-screen',
		loadChildren: () =>
			import(
				'../../mylib-user/pages/splash-screen/splash-screen.module'
			).then((m) => m.SplashScreenPageModule),
	},
	{
		path: 'blog',
		loadChildren: () =>
			import('../../mylib-user/pages/blogs/blogs.module').then(
				(m) => m.BlogsPageModule
			),
	},
	{
		path: 'blog/:slug',
		loadChildren: () =>
			import('../../mylib-user/pages/blogs/blogs.module').then(
				(m) => m.BlogsPageModule
			),
	},
	{
		path: 'add-password',
		loadChildren: () =>
			import(
				'../../mylib-user/pages/add-password/add-password.module'
			).then((m) => m.AddPasswordPageModule),
	},
	{
		path: 'work-requests',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import(
				'../../mylib-user/pages/work-requests/work-requests.module'
			).then((m) => m.WorkRequestsPageModule),
	},
	{
		path: 'work-request',
		// canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/wr/wr.module').then((m) => m.WrPageModule),
	},
	{
		path: 'work-request/:id',
		runGuardsAndResolvers: 'always',
		canDeactivate: [NavigateAwayGuard],
		loadChildren: () =>
			import('./pages/wr/wr.module').then((m) => m.WrPageModule),
	},
	{
		path: 'password-reset',
		loadChildren: () =>
			import(
				'../../mylib-user/pages/password-reset/password-reset.module'
			).then((m) => m.PasswordResetPageModule),
	},
	// {
	// 	path: 'wr',
	// 	loadChildren: () =>
	// 		import('./pages/wr/wr.module').then((m) => m.WrPageModule),
	// },
	{
		path: 'notifications',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import(
				'../../mylib-user/pages/notifications/notifications.module'
			).then((m) => m.NotificationsPageModule),
	},

	{
		path: 'documents',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('../../mylib-user/pages/documents/documents.module').then(
				(m) => m.DocumentsModule
			),
	},

	{
		path: 'messages',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/messages/messages.module').then(
				(m) => m.MessagesPageModule
			),
	},

	{
		path: 'help-center',
		loadChildren: () =>
			import('./pages/help-center/help.module').then(
				(m) => m.HelpPageModule
			),
	},

	// {
	// 	path: 'docusign',
	// 	// canActivate: [AuthGuard],
	// 	loadChildren: () =>
	// 		import('../../mylib-user/pages/docusign/docusign.module').then(
	// 			(m) => m.DocusignPageModule
	// 		),
	// },
	{
		path: '**',
		redirectTo: '/start-screen',
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/start-screen',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
