import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FirebaseApiService } from 'mylib/services/firebase/firebase.api.service';
import { MainService } from 'mylib-user/services/main/main.service';
import { UserProviderService } from 'mylib-user/services/userProvider/user-provider.service';
import { UtilService } from 'mylib-user/services/util/util.service';

@Component({
	selector: 'app-leave-feedback',
	templateUrl: './leave-feedback.component.html',
	styleUrls: ['./leave-feedback.component.scss'],
})
export class LeaveFeedbackComponent implements OnInit {
	@Input() componentProps;
	submitReviewSpinner: boolean = false;
	clickedAndInvalid: boolean = false;
	rating = 5;
	reviewMessage: string = '';
	constructor(
		private main: MainService,
		private userProvider: UserProviderService,
		private util: UtilService,
		private firebaseApi: FirebaseApiService,
		private modalCtrl: ModalController
	) {}

	ngOnInit() {
		console.log(
			'Main State- > ',
			this.componentProps,
			this.main.state.selectedWR
		);
	}

	async dismissModal() {
		await this.modalCtrl.dismiss();
	}

	async onSubmitReview(form: NgForm) {
		if (!form.valid) {
			this.clickedAndInvalid = true;
			return;
		}

		this.submitReviewSpinner = true;

		try {
			let review: any;
			if (!this.componentProps.publicProfile) {
				review = {
					vendorId: this.componentProps.vendorId,
					userId: this.userProvider.loggedInUser.id,
					firstName: this.userProvider.loggedInUser.firstName,
					lastName: this.userProvider.loggedInUser.lastName,
					profilePhoto: this.userProvider.loggedInUser.profilePhoto,
					rating: this.rating,
					message: this.reviewMessage,
					reply: '',
				};
			} else {
				review = {
					vendorId: this.componentProps.vendor.id,
					userId: '',
					firstName: this.componentProps.vendor.firstName,
					lastName: this.componentProps.vendor.lastName,
					profilePhoto: '',
					rating: this.rating,
					message: this.reviewMessage,
					reply: '',
				};
			}
			await this.firebaseApi.insertOne('reviews', review);
			this.rating = 5;
			this.reviewMessage = '';
			await this.modalCtrl.dismiss();
		} catch (e) {
			console.log('E AUTH ONSUBMIT -> ', e);
			this.util.createErrorAlert(null, e.message);
		} finally {
			this.submitReviewSpinner = false;
		}
	}

	ratingChange($event) {
		console.log('rating change -> ', $event);
		this.rating = $event;
	}
}
