<section
	class="sm show-small-header nav-bot"
	*ngIf="!_showBotNav"
	(click)="main.resetChat()"
>
	<div [routerLink]="['/dashboard']" routerLinkActive="active">
		<ion-icon name="Home-outline"></ion-icon>
		Home
	</div>

	<div [routerLink]="['/work-requests']" routerLinkActive="active">
		<ion-icon name="add-circle-outline"></ion-icon>
		Work Request
	</div>

	<div
		[routerLink]="['/messages']"
		routerLinkActive="active"
		class="relative"
	>
		<ion-icon name="mail-outline"></ion-icon>
		<div class="notif-messages msg-alert2">
			{{ main.totalUnreadMsg }}
		</div>
		Messages
	</div>

	<div [routerLink]="['/documents']" routerLinkActive="active">
		<ion-icon name="folder-outline"></ion-icon>
		Docs
	</div>

	<div>
		<ion-menu-button id="menuButton" color="whte"></ion-menu-button>
	</div>
</section>
