<div class="center-logo" *ngIf="!appLoaded">
	<div
		class="logo-container"
		style="padding: 10px; border-radius: 16px; background-color: white"
	>
		<img
			width="100"
			_ngcontent-ivy-c149=""
			src="assets/icon/nexgenlogo.png"
		/>
	</div>
	<!--  -->
	<div class="lds-facebook app">
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>

<ion-app>
	<ion-split-pane
		contentId="main-content"
		when="(max-width:768px)"
		[disabled]="true"
	>
		<ion-menu
			contentId="main-content"
			type="overlay"
			[swipeGesture]="isMobileLayout"
		>
			<!------------------------------------------------>

			<ion-content>
				<section class="header-title">
					<h3>NexGen Taxes</h3>

					<img
						[src]="
							userProvider.loggedInUser.profilePhoto
								? userProvider.loggedInUser.profilePhoto
								: 'assets/images/userDefault.png'
						"
						*ngIf="userProvider.loggedInUser.profilePhoto"
					/>

					<span>
						{{ userProvider.loggedInUser.firstName }}
						{{ userProvider.loggedInUser.lastName }}
					</span>
				</section>

				<ion-menu-toggle auto-hide="false">
					<div id="dash-menu">
						<!-- <section>
							<div
								[routerLink]="['/dashboard']"
								routerLinkActive="active"
							>
								<ion-icon name="home-outline"></ion-icon>
								<span>Home</span>
							</div>

							<div
								[routerLink]="['/work-requests']"
								routerLinkActive="active"
							>
								<ion-icon name="add-outline"></ion-icon>
								<span>Work Request</span>
							</div>

							<div
								[routerLink]="['/messages']"
								routerLinkActive="active"
								class="relative"
							>
								<ion-icon name="mail-outline"></ion-icon>
								<div class="notif-messages msg-alert2">
									{{ main.totalUnreadMsg }}
								</div>
								<span>Messages</span>
							</div>

							<div
								[routerLink]="['/documents']"
								routerLinkActive="active"
							>
								<ion-icon name="folder-outline"></ion-icon>
								<span>Documents</span>
							</div>
						</section> -->

						<section>
							<div
								[routerLink]="['/support']"
								routerLinkActive="active"
							>
								<ion-icon name="help-circle-outline"></ion-icon>
								<span>Customer Service</span>
							</div>

							<div
								[routerLink]="['//onboarding/personal-info']"
								routerLinkActive="active"
							>
								<ion-icon name="person-outline"></ion-icon>
								<span>Profile</span>
							</div>

							<div
								[routerLink]="['/notifications']"
								routerLinkActive="active"
							>
								<ion-icon name="settings-outline"></ion-icon>
								<span>Settings</span>
							</div>

							<div
								(click)="logoutAlert()"
								routerLinkActive="active"
							>
								<ion-icon name="log-out-outline"></ion-icon>
								<span>Logout</span>
							</div>
						</section>
					</div>
				</ion-menu-toggle>
			</ion-content>
		</ion-menu>

		<ion-router-outlet
			id="main-content"
			[animated]="false"
		></ion-router-outlet>
	</ion-split-pane>
</ion-app>
<app-nav-bottom
	class="sm"
	*ngIf="userProvider?.loggedInUser?.id"
></app-nav-bottom>
