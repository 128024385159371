import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProviderService } from 'mylib-user/services/userProvider/user-provider.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private userProvider: UserProviderService,
		private router: Router
	) {}

	canActivate():
		| boolean
		| Promise<boolean | UrlTree>
		| Observable<boolean | UrlTree> {
		let redirectionPath: string = '';

		if (this.userProvider.loggedInUser.id) {
			console.log(1);
			if (this.userProvider.loggedInUser.verifiedAuth) {
				console.log(2);
				return true;
			} else if (!this.userProvider.loggedInUser.verifiedAuth) {
				if (!this.userProvider.loggedInUser.phoneNumber) {
					this.router.navigate(['/add-password']);
				} else {
					this.router.navigate(['/verify-auth']);
				}
				console.log(3);
			}
		} else {
			console.log(4);
			redirectionPath = '/login';
			this.router.navigate([redirectionPath]);
			return false;
		}
	}
}
