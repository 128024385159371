import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MobileMenuService {
	pages = [
		{
			name: 'Invoices',
			url: 'invoices',
			icon: 'newspaper-outline',
			auth: true,
		},
		{
			name: 'Support',
			url: 'support',
			icon: 'help-outline',
			auth: true,
		},
		{
			name: 'Profile',
			url: 'onboarding/personal-info',
			icon: 'person-outline',
			auth: true,
		},
		{
			name: 'Notifications',
			url: 'notifications',
			icon: 'notifications-outline',
			auth: true,
		},
		{
			name: 'Sign in',
			url: 'login',
			icon: 'log-in-outline',
			auth: false,
		},
		{
			name: 'Sign Up',
			url: 'register',
			icon: 'person-outline',
			auth: false,
		},
		{
			name: 'Get Started',
			url: 'get-started',
			icon: 'key-outline',
			auth: false,
		},
		{
			name: 'Logout',
			url: 'logout',
			icon: 'log-out-outline',
			auth: true,
		},
	];

	constructor() { }
}
