import { Injectable } from '@angular/core';

import {
	UserToInsert,
	UserToStage,
	VendorToInsert,
} from 'mylib/services/auth/auth.interface';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../firebase/firebase-authentication.service';
import { FirestoreService } from '../firebase/firestore.service';
import { Observable } from 'rxjs';

import firebase from 'firebase/compat/app';

import 'firebase/auth';
import { FirebaseApiService } from '../firebase/firebase.api.service';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import {
	SignInWithApple,
	AppleSignInResponse,
	ASAuthorizationAppleIDRequest,
} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';

const notifUser = [
	{
		header: 'New Document',
		disclaimer: 'Sent when a vendor uploads a new document',
		key: 'new-document',
		sms: true,
		email: true,
	},
	{
		header: 'Unfinished Work Request',
		disclaimer: 'Sent when you have not finished your work request',
		key: 'unfinished-work-request',
		sms: true,
		email: true,
	},
	{
		header: 'Unfinished Profile',
		disclaimer: 'Sent once in a while when your profile is not completed',
		key: 'unfinished-profile',
		sms: true,
		email: true,
	},
	{
		header: 'Support Ticket',
		disclaimer: 'When support replies to your open ticket.',
		key: 'new-support-ticket',
		sms: true,
		email: true,
	},
];

const notifVendor = [
	{
		header: 'New Document',
		disclaimer: 'Send when a user uploads a new document',
		key: 'new-document',
		sms: true,
		email: true,
	},
	{
		header: 'New Lead Auto Purchased',
		disclaimer: 'Sent when the system purchases a new lead for you',
		key: 'new-lead-auto',
		sms: true,
		email: true,
	},
	{
		header: 'New Lead Arrives',
		disclaimer:
			'Sent when a new lead is distributed to you and is available for purchase',
		key: 'new-lead-arrives',
		sms: true,
		email: true,
	},
	{
		header: 'Unfinished Profile',
		disclaimer: 'Sent once in a while when your profile is not completed',
		key: 'unfinished-profile',
		sms: true,
		email: true,
	},
	{
		header: 'Support Ticket',
		disclaimer: 'When support replies to your open ticket.',
		key: 'new-support-ticket',
		sms: true,
		email: true,
	},
];

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	state = {
		googleSpinner: false,
		appleSpinner: false,
	};
	constructor(
		private auth: AuthenticationService,
		private firestore: FirestoreService,
		private firebaseApi: FirebaseApiService,
		private googlePlus: GooglePlus,
		private signInWithApple: SignInWithApple
	) {}

	vendorToInsert(userData: {
		id: string;
		phoneNumber: string;
		email: string;
		type: string;
		firstName: string;
		lastName: string;
		referringUserId?: string;
		teamId?: string;
	}): VendorToInsert {
		return {
			id: userData.id,
			phoneNumber: userData.phoneNumber,
			email: userData.email,
			createdAt: new Date().toISOString(),
			firstName: userData.firstName ? userData.firstName : '',
			lastName: userData.lastName ? userData.lastName : '',
			type: userData.type,
			referringUserId: userData.referringUserId
				? userData.referringUserId
				: '',
			stripeAccountId: '', // fordemo
			role: 'vendor',
			teamId: userData.teamId ? userData.teamId : '',
			approvedToWork: false,
			emailVerified: false,
			credits: 0,
			autoTopOff: false,
			autoTopOffAmount: 0,
			notifVendor,
			fromApp: environment.appIsFor,
		};
	}

	adminToInsert(userData: {
		id: string;
		phoneNumber: string;
		email: string;
		firstName: string;
		lastName: string;
	}): any {
		return {
			id: userData.id,
			phoneNumber: userData.phoneNumber,
			email: userData.email,
			createdAt: new Date().toISOString(),
			firstName: userData.firstName ? userData.firstName : '',
			lastName: userData.lastName ? userData.lastName : '',
			role: 'admin',
		};
	}

	userToInsert(userData: {
		id: string;
		phoneNumber: string;
		email: string;
		firstName: string;
		lastName: string;
		notifUser: any[];
	}): UserToInsert {
		return {
			id: userData.id,
			firstName: userData.firstName ? userData.firstName : '',
			lastName: userData.lastName ? userData.lastName : '',
			email: userData.email,
			onboardingSteps: 0,
			dateOfBirth: '',
			createdAt: new Date().toISOString(),
			type: 'user',
			profilePhoto: '',
			haveBusiness: 'No',
			emailVerified: false,
			language: [
				{ l: 'English', sel: true },
				{ l: 'Spanish', sel: false },
				{ l: '', sel: false },
			],
			travel: [
				{ t: 'Pros travel to you', sel: false },
				{ t: 'I will travel with the pros', sel: false },
				{ t: 'I want to work remotely', sel: false },
			],
			completedStep: 0,
			address: {
				line1: '',
				line2: '',
				city: '',
				state: '',
				zipcode: '',
			},
			business: {
				name: '',
				phoneNumber: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					zipcode: '',
				},
			},
			notifUser: userData.notifUser,
			documents: [],
			social: {},
			verified: false,
			phoneNumber: userData.phoneNumber,
			role: 'user',
			fromApp: environment.appIsFor,
		};
	}

	async delete(collection: string, id: string) {
		return new Promise<any>(async (resolve, reject) => {
			try {
				await this.firestore.delete(collection, id);
				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	}

	getSingleUser(id) {
		return new Promise<any>((resolve, reject) => {
			this.addIdToObject(
				id,
				this.firestore.getOne('users', id)
			).subscribe(resolve, reject);
		});
	}

	addIdToObject(id, obj: Observable<any>) {
		return new Observable((observer) => {
			if (id) {
				obj.subscribe(
					(ref) => {
						const newObj = ref;
						if (newObj) {
							newObj.id = id;
						}
						observer.next(newObj);
					},
					(err) => {
						observer.error(err);
					}
				);
			} else {
				observer.error({ message: 'No ID' });
			}
		});
	}

	async signUp(userData: UserToStage) {
		return new Promise(async (resolve, reject) => {
			try {
				const userWithId = await this.auth.createAccount(
					userData.email,
					userData.password
				);

				const signUpIn = await this.auth.login(
					userData.email,
					userData.password
				);
				let cometUser: {
					id: string;
					firstName: string;
					lastName: string;
					email: string;
					contactNumber: string;
				};

				let promise: Promise<any>;
				switch (environment.userRole) {
					case 'user': {
						const userToInsert = {
							id: userWithId.uid,
							phoneNumber: userData.phoneNumber,
							email: userData.email,
							firstName: '',
							lastName: '',
							notifUser: userData.notifUser,
						};
						cometUser = {
							id: userWithId.uid,
							firstName: '',
							lastName: '',
							email: userData.email,
							contactNumber: userData.phoneNumber,
						};

						promise = this.insertUser(
							this.userToInsert(userToInsert)
						);
						break;
					}
					case 'admin': {
						const adminToInsert = {
							id: userWithId.uid,
							phoneNumber: userData.phoneNumber,
							email: userData.email,
							firstName: '',
							lastName: '',
						};
						cometUser = {
							id: userWithId.uid,
							firstName: '',
							lastName: '',
							email: userData.email,
							contactNumber: userData.phoneNumber,
						};
						promise = this.insertUser(
							this.adminToInsert(adminToInsert)
						);
						break;
					}
					case 'vendor': {
						const vendorToInsert = {
							id: userWithId.uid,
							phoneNumber: userData.phoneNumber,
							email: userData.email,
							firstName: userData.firstName
								? userData.firstName
								: '',
							lastName: userData.lastName
								? userData.lastName
								: '',
							referringUserId: userData.referringUserId
								? userData.referringUserId
								: '',
							type: userData.role,
							teamId: userData.teamId ? userData.teamId : '',
						};
						cometUser = {
							id: userWithId.uid,
							firstName: vendorToInsert.firstName,
							lastName: vendorToInsert.lastName,
							email: userData.email,
							contactNumber: userData.phoneNumber,
						};

						promise = this.insertUser(
							this.vendorToInsert(vendorToInsert)
						);
						break;
					}
				}

				// await this.createCometChatUser(
				//     cometUser.id,
				//     cometUser.firstName,
				//     cometUser.lastName,
				//     cometUser.email,
				//     cometUser.contactNumber
				// );
				const id = await promise;
				resolve(id);
			} catch (err) {
				reject(err);
			}
		});
	}

	async login(userData: { email: string; password: string }) {
		return new Promise((resolve, reject) => {
			this.auth
				.login(userData.email, userData.password)
				.then((user) => {
					resolve(user.uid);
				})
				.catch((err) => {
					if (err.message.includes('auth/wrong-password')) {
						reject({ message: 'Wrong or incorrect password' });
					} else if (err.message.includes('auth/user-not-found')) {
						reject({
							message:
								'This user account associated to this email address does not exist',
						});
					} else reject(err);
				});
		});
	}

	async insertUser(userToInsert: any) {
		try {
			await this.firestore.createWithId('users', userToInsert);
			return userToInsert.id;
		} catch (e) {
			throw new Error('Could not insert user');
		}
	}

	async googleSignInMobile() {
		try {
			this.state.googleSpinner = true;

			const googleLoginRes = await this.googlePlus.login({
				webClientId:
					'1051749352549-stddbsb224ikit9u7dtvho5poc4bmjhg.apps.googleusercontent.com',
			});
			//

			let cometUser: {
				id: string;
				firstName: string;
				lastName: string;
				email: string;
				contactNumber: string;
			};

			let promise: Promise<any>;

			const fireBaseUser: any = await this.firestore.firebaseGoogleSignIn(
				googleLoginRes.idToken,
				googleLoginRes.accessToken
			);

			if (fireBaseUser.additionalUserInfo.isNewUser) {
				const userToInsert = {
					id: fireBaseUser.user.uid,
					email: googleLoginRes.email,
					phoneNumber: '',
					firstName: googleLoginRes.givenName
						? googleLoginRes.givenName
						: '',
					lastName: googleLoginRes.familyName
						? googleLoginRes.familyName
						: '',
					notifUser,
				};
				cometUser = {
					id: fireBaseUser.user.uid,
					firstName: googleLoginRes.givenName
						? googleLoginRes.givenName
						: '',
					lastName: googleLoginRes.familyName
						? googleLoginRes.familyName
						: '',
					email: fireBaseUser.email,
					contactNumber: '',
				};
				promise = this.insertUser(this.userToInsert(userToInsert));

				// await this.createCometChatUser(
				//     cometUser.id,
				//     cometUser.firstName,
				//     cometUser.lastName,
				//     cometUser.email,
				//     cometUser.contactNumber
				// );

				const userId = await promise;
				return {
					userId,
					isNewUser: fireBaseUser.additionalUserInfo.isNewUser,
				};
			} else {
				return {
					userId: fireBaseUser.user.uid,
					isNewUser: fireBaseUser.additionalUserInfo.isNewUser,
				};
			}
		} catch (e) {
			throw new Error(e.message);
		} finally {
		}
	}

	async googleSignIn() {
		try {
			const provider = new firebase.auth.GoogleAuthProvider();
			const googleUser: any = await firebase
				.auth()
				.signInWithPopup(provider);
			const foundUser: any = await this.firebaseApi.findOneById(
				'users',
				googleUser.user.uid
			);

			let cometUser: {
				id: string;
				firstName: string;
				lastName: string;
				email: string;
				contactNumber: string;
			};

			if (foundUser) {
				if (foundUser.role === environment.userRole) {
					return {
						userId: foundUser.id,
						isNewUser: googleUser.additionalUserInfo.isNewUser,
					};
				} else
					throw new Error(
						`This user account is not registered for this website`
					);
			} else {
				let promise: Promise<any>;
				switch (environment.userRole) {
					case 'user': {
						const userToInsert = {
							id: googleUser.user.uid,
							email: googleUser.additionalUserInfo.profile.email,
							phoneNumber: '',
							firstName: googleUser.additionalUserInfo.profile
								.given_name
								? googleUser.additionalUserInfo.profile
										.given_name
								: '',
							lastName: googleUser.additionalUserInfo.profile
								.family_name
								? googleUser.additionalUserInfo.profile
										.family_name
								: '',
							notifUser,
						};
						cometUser = {
							id: googleUser.user.uid,
							firstName: googleUser.additionalUserInfo.profile
								.given_name
								? googleUser.additionalUserInfo.profile
										.given_name
								: '',
							lastName: googleUser.additionalUserInfo.profile
								.family_name
								? googleUser.additionalUserInfo.profile
										.family_name
								: '',
							email: googleUser.additionalUserInfo.profile.email,
							contactNumber: '',
						};
						promise = this.insertUser(
							this.userToInsert(userToInsert)
						);
						break;
					}
					case 'vendor': {
						const vendorToInsert = {
							id: googleUser.user.uid,
							email: googleUser.additionalUserInfo.profile.email,
							phoneNumber: '',
							firstName: googleUser.additionalUserInfo.profile
								.given_name
								? googleUser.additionalUserInfo.profile
										.given_name
								: '',
							lastName: googleUser.additionalUserInfo.profile
								.family_name
								? googleUser.additionalUserInfo.profile
										.family_name
								: '',
							type: 'vendor',
						};
						cometUser = {
							id: googleUser.user.uid,
							firstName: googleUser.additionalUserInfo.profile
								.given_name
								? googleUser.additionalUserInfo.profile
										.given_name
								: '',
							lastName: googleUser.additionalUserInfo.profile
								.family_name
								? googleUser.additionalUserInfo.profile
										.family_name
								: '',
							email: googleUser.additionalUserInfo.profile.email,
							contactNumber: '',
						};
						promise = this.insertUser(
							this.vendorToInsert(vendorToInsert)
						);
						break;
					}
				}

				// await this.createCometChatUser(
				//     cometUser.id,
				//     cometUser.firstName,
				//     cometUser.lastName,
				//     cometUser.email,
				//     cometUser.contactNumber
				// );

				const userId = await promise;
				return {
					userId,
					isNewUser: googleUser.additionalUserInfo.isNewUser,
				};
			}
		} catch (e) {
			throw new Error(e.message);
		} finally {
			this.state.googleSpinner = false;
		}
	}

	async appleSignInMobile() {
		try {
			this.state.appleSpinner = true;
			const appleSignInRes: AppleSignInResponse =
				await this.signInWithApple.signin({
					requestedScopes: [
						ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
						ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
					],
				});

			let cometUser: {
				id: string;
				firstName: string;
				lastName: string;
				email: string;
				contactNumber: string;
			};

			const userCredential: any =
				await this.firestore.firebaseAppleSignIn(appleSignInRes);

			if (userCredential.additionalUserInfo.isNewUser) {
				const userToInsert = {
					id: userCredential.user.uid,
					phoneNumber: '',
					email: appleSignInRes.email,
					firstName: appleSignInRes.fullName.givenName
						? appleSignInRes.fullName.givenName
						: '',
					lastName: appleSignInRes.fullName.familyName
						? appleSignInRes.fullName.familyName
						: '',
					notifUser,
				};
				cometUser = {
					id: userCredential.user.uid,
					firstName: appleSignInRes.fullName.givenName
						? appleSignInRes.fullName.givenName
						: '',
					lastName: appleSignInRes.fullName.familyName
						? appleSignInRes.fullName.familyName
						: '',
					email: appleSignInRes.email,
					contactNumber: '',
				};
				const promise: Promise<any> = this.insertUser(
					this.userToInsert(userToInsert)
				);

				// await this.createCometChatUser(
				//     cometUser.id,
				//     cometUser.firstName,
				//     cometUser.lastName,
				//     cometUser.email,
				//     cometUser.contactNumber
				// );
				const userId = await promise;
				await this.firebaseApi.updateOne('users', userId, {
					verifiedAuth: true,
				});
				return {
					userId,
					isNewUser: userCredential.additionalUserInfo.isNewUser,
				};
			} else {
				await this.firebaseApi.updateOne(
					'users',
					userCredential.user.uid,
					{
						verifiedAuth: true,
					}
				);
				return {
					userId: userCredential.user.uid,
					isNewUser: userCredential.additionalUserInfo.isNewUser,
				};
			}
		} catch (e) {
			throw new Error(e.message);
		}
	}

	async appleSignIn() {
		try {
			const provider = new firebase.auth.OAuthProvider('apple.com');
			provider.addScope('email');
			provider.addScope('name');
			const appleUser: any = await firebase
				.auth()
				.signInWithPopup(provider);

			const foundUser: any = await this.firebaseApi.findOneById(
				'users',
				appleUser.user.uid
			);

			let cometUser: {
				id: string;
				firstName: string;
				lastName: string;
				email: string;
				contactNumber: string;
			};

			if (foundUser) {
				if (foundUser.role === environment.userRole) {
					return {
						userId: foundUser.id,
						isNewUser: appleUser.additionalUserInfo.isNewUser,
					};
				} else
					throw new Error(
						`This user account is not registered for this website`
					);
			} else {
				let promise: Promise<any>;
				switch (environment.userRole) {
					case 'user': {
						const userToInsert = {
							id: appleUser.user.uid,
							phoneNumber: '',
							email: appleUser.additionalUserInfo.profile.email,
							firstName: appleUser.additionalUserInfo.profile
								.firstName
								? appleUser.additionalUserInfo.profile.firstName
								: '',
							lastName: appleUser.additionalUserInfo.profile
								.lastName
								? appleUser.additionalUserInfo.profile.lastName
								: '',
							notifUser,
						};
						cometUser = {
							id: appleUser.user.uid,
							firstName: appleUser.additionalUserInfo.profile
								.firstName
								? appleUser.additionalUserInfo.profile.firstName
								: '',
							lastName: appleUser.additionalUserInfo.profile
								.lastName
								? appleUser.additionalUserInfo.profile.lastName
								: '',
							email: appleUser.additionalUserInfo.profile.email,
							contactNumber: '',
						};
						promise = this.insertUser(
							this.userToInsert(userToInsert)
						);
						break;
					}
					case 'vendor': {
						const vendorToInsert = {
							id: appleUser.user.uid,
							phoneNumber: '',
							email: appleUser.additionalUserInfo.profile.email,
							firstName: appleUser.additionalUserInfo.profile
								.firstName
								? appleUser.additionalUserInfo.profile.firstName
								: '',
							lastName: appleUser.additionalUserInfo.profile
								.lastName
								? appleUser.additionalUserInfo.profile.lastName
								: '',
							type: 'vendor',
						};
						cometUser = {
							id: appleUser.user.uid,
							firstName: appleUser.additionalUserInfo.profile
								.firstName
								? appleUser.additionalUserInfo.profile.firstName
								: '',
							lastName: appleUser.additionalUserInfo.profile
								.lastName
								? appleUser.additionalUserInfo.profile.lastName
								: '',
							email: appleUser.additionalUserInfo.profile.email,
							contactNumber: '',
						};
						promise = this.insertUser(
							this.vendorToInsert(vendorToInsert)
						);
						break;
					}
				}

				// await this.createCometChatUser(
				//     cometUser.id,
				//     cometUser.firstName,
				//     cometUser.lastName,
				//     cometUser.email,
				//     cometUser.contactNumber
				// );
				const userId = await promise;
				return {
					userId,
					isNewUser: appleUser.additionalUserInfo.isNewUser,
				};
			}
		} catch (e) {
			throw new Error('Error with apple signin');
		} finally {
			this.state.appleSpinner = false;
		}
	}

	logout() {
		return this.auth.logout();
	}
}
