import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { MainService } from 'mylib-user/services/main/main.service';
import { WRMENU } from 'src/assets/data/wr-top-menu';
import { environment } from 'src/environments/environment';
import { LeaveFeedbackComponent } from '../leave-feedback/leave-feedback.component';

@Component({
    selector: 'app-wr-menu',
    templateUrl: './wr-menu.component.html',
    styleUrls: ['./wr-menu.component.scss'],
})
export class WrMenuComponent implements OnInit {
    @Output() changeChatMenu: EventEmitter<any> = new EventEmitter();
    @Input() slug: string = '';
    @Input() workRequest;
    @Input() menu = 'desktop';

    constructor(
        public main: MainService,
        private modalCtrl: ModalController,
        private popoverController: PopoverController
    ) { }

    ngOnInit() {
        console.log('params ->', this.slug, this.menu);
    }

    eventFromPopover() {
        this.popoverController.dismiss('pop over event');
    }

    goToMenu(slug: string) {
        this.changeChatMenu.emit({ slug });
    }

    goToMobileMenu(slug: string) {
        this.popoverController.dismiss({ slug });
    }

    async feedbackPopout(hiredProp) {
        const modalOpts: ModalOptions = {
            component: LeaveFeedbackComponent,
            componentProps: { componentProps: hiredProp },
            cssClass: 'center-modal-small',
            animated: true,
        };
        const modal = await this.modalCtrl.create(modalOpts);
        await modal.present();
        modal.onDidDismiss().then((dismiss) => {
            console.log('DIsmiss', dismiss);
        });
    }

    get hasHiredProposal() {
        return this.main.state.tasks.find(
            (t) => t.hired === true && t.workRequestId === this.main.state.wRid
        );
    }

    get MenuItems() {
        const hasProposals = this.main.state.tasks.find(
            (t) => t.workRequestId === this.main.state.wRid
        );

        let WRMENUCOPY = WRMENU;

        if (environment.appIsFor === 'forMyTax') {
            WRMENUCOPY = WRMENUCOPY.filter(
                (m) => m.link !== 'proposals' && m.link !== 'details'
            );
            WRMENUCOPY.forEach((w: any) => w.name === w.nameMobile);
            return WRMENUCOPY;
        }

        if (environment.appIsFor === 'rushTimeTax') {
            WRMENUCOPY = WRMENUCOPY.filter((m) => m.link !== 'proposals');
            WRMENUCOPY.forEach((w: any) => w.name === w.nameMobile);
            return WRMENUCOPY;
        }

        if (environment.appIsFor === 'nexGenTaxes') {
            if (this.hasHiredProposal)
                return WRMENUCOPY.filter((m) => m.link !== 'proposals');
            else if (hasProposals)
                return WRMENUCOPY.filter((m) => m.link !== 'appointments');
            else return WRMENUCOPY.filter((m) => m.link !== 'appointments');
        }
    }
}
