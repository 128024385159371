import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);
console.log('prod env:', environment.production);
if (environment.production) {
	window.console.log = function () {}; // disable any console.log debugging statements in production mode
	enableProdMode();
	console.log('env -> prod');
} else console.log('env -> qa');

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));
