import {
	APP_INITIALIZER,
	ErrorHandler,
	NgModule,
	CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxStripeModule } from 'ngx-stripe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // use import {NgIdleModule} from '@ng-idle/core'; if not using keepalive
import { FirestoreService } from 'mylib/services/firebase/firestore.service';
import { AuthenticationService } from 'mylib/services/firebase/firebase-authentication.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { UserProviderService } from 'mylib-user/services/userProvider/user-provider.service';
import { WrMenuModule } from 'mylib-user/components/wr-menu/wr-menu.module';
import { register } from 'swiper/element/bundle';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NavBottomModule } from 'mylib-user/components/nav-bottom/nav-bottom.module';

register();

export const initUserProviderFactory =
	(provider: UserProviderService) => async () => {
		await provider.checkAuthState();
	};

@NgModule({
	declarations: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot({ name: 'taxuserappdb' }),
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule,
		NgxStripeModule.forRoot(environment.stripe.publishable),
		FormsModule,
		ReactiveFormsModule,
		NgIdleKeepaliveModule.forRoot(), // use NgIdleModule.forRoot() if not using keepalive
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFireAuthModule,
		WrMenuModule,
		NgxCsvParserModule,
		NavBottomModule,
	],
	providers: [
		AuthenticationService,
		FirestoreService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		UserProviderService,
		DatePipe,
		GooglePlus,
		SignInWithApple,
		{
			provide: APP_INITIALIZER,
			useFactory: initUserProviderFactory,
			deps: [UserProviderService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
