import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NavBottomComponent } from './nav-bottom.component';

@NgModule({
	declarations: [NavBottomComponent],
	exports: [NavBottomComponent],
	imports: [IonicModule, CommonModule, RouterModule],
})
export class NavBottomModule {}
