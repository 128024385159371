import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'mylib-user/services/main/main.service';
import { UserProviderService } from 'mylib-user/services/userProvider/user-provider.service';

@Component({
	selector: 'app-nav-bottom',
	templateUrl: './nav-bottom.component.html',
	styleUrls: ['./nav-bottom.component.scss'],
})
export class NavBottomComponent implements OnInit {
	constructor(
		public main: MainService,
		private u: UserProviderService,
		private router: Router
	) {}

	ngOnInit() {}

	get _showBotNav() {
		const url = this.router.url;
		const s =
			(url.indexOf('quest') >= 0 ||
				url.indexOf('get-started') >= 0 ||
				url.indexOf('personal-info') >= 0 ||
				url.indexOf('verify-auth') >= 0 ||
				url.indexOf('login') >= 0 ||
				url.indexOf('register') >= 0) &&
			url.indexOf('work-request') < 0
				? true
				: false;
		return s;
	}
}
