import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseDatabaseModel } from './base-dto.model';

@Injectable()
export class FirestoreService {
    userid: any;

    constructor(
        public store: AngularFirestore,
        private afAuth: AngularFireAuth
    ) {
        this.userid = 1;
    }

    async firebaseAppleSignIn(appleResponse) {
        return new Promise(async (resolve, reject) => {
            try {
                const provider = new firebase.auth.OAuthProvider('apple.com');
                const credential = provider.credential({
                    idToken: appleResponse.identityToken,
                });
                const userCredential = await this.afAuth.signInWithCredential(
                    credential
                );
                resolve(userCredential);
            } catch (e) {
                reject(e);
            }
        });
    }

    async firebaseGoogleSignIn(accessToken, accessSecret) {
        return new Promise(async (resolve, reject) => {
            try {
                const credential = accessSecret
                    ? firebase.auth.GoogleAuthProvider.credential(
                        accessToken,
                        accessSecret
                    )
                    : firebase.auth.GoogleAuthProvider.credential(accessToken);
                const user = await this.afAuth.signInWithCredential(credential);
                resolve(user);
            } catch (e) {
                reject(e);
            }
        });
    }

    public find<T extends BaseDatabaseModel>(
        collection: string,
        queryFn: QueryFn
    ): Observable<T[]> {
        return new Observable((observer) => {
            let col;
            if (queryFn) col = this.store.collection<T>(collection, queryFn);
            else col = this.store.collection<T>(collection);
            col.valueChanges({ idField: 'id' })
                .pipe(take(1))
                .subscribe(
                    (docs) => {
                        observer.next(docs);
                    },
                    (err) => {
                        observer.error(err);
                    }
                );
        });
    }

    public update<T extends BaseDatabaseModel>(
        collection: string,
        id: string,
        document: Partial<T>
    ): Promise<void> {
        return this.store
            .doc<T>(`${collection}/${id}`)
            .update(this.addUpdatedAt(document));
    }

    public delete<T extends BaseDatabaseModel>(
        collection: string,
        id: string
    ): Promise<void> {
        return this.store.doc<T>(`${collection}/${id}`).delete();
    }

    public createWithId<T extends BaseDatabaseModel>(
        collection: string,
        data: T
    ): Promise<void> {
        return this.store
            .doc<T>(`${collection}/${data.id}`)
            .set(this.addCreatedAt(data));
    }

    public async create<T extends BaseDatabaseModel>(
        collection: string,
        data: T
    ): Promise<
        firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    > {
        const doc = await this.store
            .collection<T>(collection)
            .add(this.addCreatedAt(data));
        return doc.get();
    }

    public getOne<T extends BaseDatabaseModel>(
        collection: string,
        id: string
    ): Observable<T> {
        return this.store
            .doc<T>(`${collection}/${id}`)
            .valueChanges()
            .pipe(take(1));
    }

    addCreatedAt(data) {
        return {
            ...data,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
    }
    addUpdatedAt(data) {
        return {
            ...data,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
    }
}

export interface FirestoreQuery {
    field: string;
    operation: firebase.firestore.WhereFilterOp;
    searchKey: string;
}
