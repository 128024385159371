export const WRMENU = [
	{
		order: 1,
		img: '',
		name: 'Overview',
		nameMobile: 'View',
		link: 'overview',
		icon: 'view',
	},
	{
		order: 2,
		img: '',
		name: 'Proposals',
		nameMobile: 'Proposals',
		link: 'proposals',
		icon: 'proposal',
	},
	{
		order: 3,
		img: '',
		name: 'Messages',
		nameMobile: 'Msgs',
		link: 'messages',
		icon: 'message',
	},
	{
		order: 4,
		img: '',
		name: 'Appointments',
		nameMobile: 'Appoint.',
		link: 'appointments',
		icon: 'appointments',
	},
	{
		order: 4,
		img: '',
		name: 'Documents',
		nameMobile: 'Docs',
		link: 'documents',
		icon: 'docs',
	},
	{
		order: 5,
		img: '',
		name: 'Details',
		nameMobile: 'Details',
		link: 'details',
		icon: 'details',
	},
];
