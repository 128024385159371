import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import {
	Config,
	MenuController,
	Platform,
	PopoverController,
} from '@ionic/angular';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UtilService } from '../../mylib-user/services/util/util.service';
import { MainService } from '../../mylib-user/services/main/main.service';
import { MobileMenuService } from '../../mylib-user/services/mobile-mobile/mobile-menu.service';
// import { SplashScreen } from '@capacitor/splash-screen';
import { UserProviderService } from 'mylib-user/services/userProvider/user-provider.service';
import { Capacitor } from '@capacitor/core';
import { FirebaseApiService } from 'mylib/services/firebase/firebase.api.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	isMobileLayout = false;
	appLoaded: boolean = false;
	isWRSelected = 0;
	finisedWR = [];
	wrSelectedId = '';

	// some fields to store our state so we can display it in the UI
	idleState = 'NOT_STARTED';
	countdown?: number = null;
	lastPing?: Date = null;

	constructor(
		public userProvider: UserProviderService,
		public menu: MobileMenuService,
		public popoverController: PopoverController,
		private firebaseApi: FirebaseApiService,
		private platform: Platform,
		private menuCtrl: MenuController,
		public router: Router,
		private idle: Idle,
		private util: UtilService,
		keepalive: Keepalive,
		cd: ChangeDetectorRef,
		public main: MainService
	) {
		console.log('CONSTRUCT');
		// set idle parameters
		idle.setIdle(5); // how long can they be inactive before considered idle, in seconds
		idle.setTimeout(15 * 60); // how long can they be idle before considered timed out, in seconds
		// idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

		idle.onIdleStart.subscribe(async () => {
			// console.log('Idle Start - > ', new Date());
			this.idleState = 'IDLE';
			if (this.userProvider.loggedInUser.id)
				await this.userProvider.lastSeen(new Date());
			this.logIdle();
		});

		idle.onIdleEnd.subscribe(async () => {
			// console.log('Idle End - > ', new Date());

			this.idleState = 'NOT_IDLE';
			// console.log(`${this.idleState} ${new Date()}`)
			if (this.userProvider.loggedInUser.id)
				await this.userProvider.lastSeen(new Date());
			this.countdown = null;
			cd.detectChanges(); // how do i avoid this kludge?
			this.logIdle();
		});

		idle.onTimeout.subscribe(() => {
			console.log('Idle Timeout - > ', new Date());

			this.idleState = 'TIMED_OUT';
			this.logout(true);
		});

		idle.onTimeoutWarning.subscribe((seconds) => {
			// console.log('Idle warning - > ', new Date(), seconds);

			this.countdown = seconds;
			this.logIdle(this.countdown);
		});

		// set keepalive parameters, omit if not using keepalive
		keepalive.interval(15); // will ping at this interval while not idle, in seconds
		keepalive.onPing.subscribe(() => (this.lastPing = new Date())); // do something when it pings
	}

	reset() {
		// we'll call this method when we want to start/reset the idle process
		// reset any component state and be sure to call idle.watch()
		this.idle.watch();
		this.idleState = 'NOT_IDLE';
		this.countdown = null;
		this.lastPing = null;
		this.logIdle();
	}

	ngOnInit(): void {
		// right when the component initializes, start reset state and start watching
		this.platform.ready().then(async () => {
			if (Capacitor.getPlatform() === 'web') {
				this.insertHotJar();
			}
			console.log('Loaded');
			this.menuCtrl.enable(true);
			window.onresize = () =>
				(this.isMobileLayout = window.innerWidth <= 992);
			// await SplashScreen.hide({
			// 	fadeOutDuration: 100,
			// });
			// this.appLoaded = true;
			this.reset();
		});
	}

	insertHotJar() {
		// let node = document.createElement('script'); // creates the script tag
		// node.innerHTML = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2908742,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
		// // append to head of document
		// document.getElementsByTagName('head')[0].appendChild(node);
	}

	logIdle(sec?) {
		// console.log('idle status ->', this.idleState, sec)
	}

	gotoPage(url) {
		switch (url) {
			case 'logout':
				this.logout();
				break;
			case 'signin':
				this.main.isLoginMode = true;
				this.util.navigateRoot('login');
				break;
			case 'signup':
				this.main.isLoginMode = false;
				this.util.navigateRoot('register');
				break;
			default:
				this.util.navigateForward(url);
		}
	}

	changeChatMenu(slug) {
		console.log('slug ->', slug);
		const queryParams = slug.vendorId
			? { id: this.main.state.wRid, vendor: slug.vendorId }
			: { id: this.main.state.wRid };
		this.main._isProfile = slug.slug.indexOf('profile') >= 0 ? true : false;
		this.router.navigate(['work-request', slug.slug], {
			queryParams: queryParams,
		});
		//
	}

	getFinishedWork() {
		const wr = this.main.state.workRequests;
		this.wrSelectedId = '';
		this.finisedWR = wr ? wr.filter((w) => w.status === 'finished') : [];
		console.log('finished wr ->', this.finisedWR);
	}

	selectWr(wr) {
		this.main.state.wRid = wr.id;
		this.main.state.selectedWr = wr;
		this.wrSelectedId = this.wrSelectedId !== wr.id ? wr.id : '';
		console.log('finished wr ->', this.main.state);
	}

	async logoutAlert() {
		const alert = await this.util.createAlert(
			'Would you like to sign-out?',
			false,
			'This will logout from your current device!',
			{
				text: 'Logout',
				handler: async () => {
					this.logout();
				},
			},
			{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'primary',
				handler: () => {},
			}
		);
		alert.present();
	}

	private async logout(_isShortLogout?: boolean) {
		this.userProvider
			.logout(_isShortLogout)
			.then(() => {
				this.menuCtrl.enable(false);
				this.popoverController.dismiss();
			})
			.catch((e) => {
				console.log('e ->', e);
			});
	}
}
