import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WrMenuComponent } from './wr-menu.component';
import { LeaveFeedbackComponent } from '../leave-feedback/leave-feedback.component';
import { FormsModule } from '@angular/forms';
import { StarRatingComponent } from 'mylib/components/star-rating/star-rating.component';


@NgModule({
	declarations: [
		WrMenuComponent,
		LeaveFeedbackComponent,
		StarRatingComponent,
	],
	exports: [WrMenuComponent],
	imports: [CommonModule, IonicModule, FormsModule],
})
export class WrMenuModule { }
