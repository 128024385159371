<!----------------------- Desktop Menu---------------------------->

<div id="wr-nav" *ngIf="menu == 'desktop'">
    <div
        id="leaveFeedback"
        (click)="feedbackPopout(hasHiredProposal)"
        *ngIf="hasHiredProposal"
    >
        Leave Feedback
    </div>
    <div
        *ngFor="let m of MenuItems"
        class="wr-nav-item"
        (click)="goToMenu(m.link)"
        [ngClass]="{ selected: slug === m.link }"
    >
        <span
            class="notif"
            *ngIf="
                (m.name === 'Documents' &&
                    workRequest.newDocument &&
                    slug !== 'documents') ||
                (m.name === 'Proposals' &&
                    workRequest.newTask &&
                    slug !== 'proposals') ||
                (m.name === 'Messages' &&
                    workRequest.newMessage &&
                    slug !== 'messages')
            "
        >
            <ion-icon name="notifications-outline"></ion-icon>
        </span>
        {{ m.name }}
    </div>
</div>

<!-- ------------------ Mobile Menu ----------------------------->

<ion-list *ngIf="menu == 'mobile'">
    <div
        id="leaveFeedback"
        (click)="feedbackPopout(hasHiredProposal)"
        *ngIf="hasHiredProposal"
    >
        Leave Feedback
    </div>
    <ion-item
        *ngFor="let m of MenuItems"
        class="pointer"
        (click)="goToMobileMenu(m.link)"
        [ngClass]="{ selected: slug === m.link }"
    >
        <span
            class="notif"
            *ngIf="
                (m.name === 'Documents' &&
                    workRequest.newDocument &&
                    slug !== 'documents') ||
                (m.name === 'Proposals' &&
                    workRequest.newTask &&
                    slug !== 'proposals') ||
                (m.name === 'Messages' &&
                    workRequest.newMessage &&
                    slug !== 'messages')
            "
        >
            <ion-icon name="notifications-outline"></ion-icon>
        </span>
        <ion-icon
            name="{{ m.icon }}"
            class="f1-2 margin-right-05"
            slot="start"
        ></ion-icon>
        <span class="b-c f0-95">{{ m.name }}</span>
    </ion-item>
</ion-list>

<!-- ------------------ Tabs Menu ----------------------------->

<ion-toolbar *ngIf="menu == 'tabs'">
    <ion-tabs>
        <ion-tab-bar slot="bottom" class="nav-bg">
            <ion-tab-button
                *ngFor="let m of MenuItems"
                (click)="goToMenu(m.link)"
                class="nav-bg"
            >
                <img [src]="'assets/nav/' + m.icon + '.png'" class="nav-icon" />
                <ion-label
                    class="f0-8 white"
                    [ngClass]="{ selected: slug === m.link }"
                >
                    {{ m.nameMobile }}
                </ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
</ion-toolbar>

<!----------------------------- Mobile side menu-------------->

<ion-list *ngIf="menu == 'mobileSideMenu'">
    <ion-item
        *ngFor="let m of MenuItems"
        class="margin-left-3 b-c pointer"
        lines="none"
        (click)="goToMenu(m.link)"
    >
        <span
            class="notif"
            *ngIf="
                (m.name === 'Documents' &&
                    workRequest?.newDocument &&
                    slug !== 'documents') ||
                (m.name === 'Proposals' &&
                    workRequest?.newTask &&
                    slug !== 'proposals') ||
                (m.name === 'Messages' &&
                    workRequest?.newMessage &&
                    slug !== 'messages')
            "
        >
            <ion-icon name="notifications-outline"></ion-icon>
        </span>
        <ion-icon
            name="{{ m.icon }}"
            class="f1-0 small-icon margin-right-1"
            slot="start"
        ></ion-icon>
        <span class="f0-9 b-c">{{ m.name }}</span>
    </ion-item>
</ion-list>
